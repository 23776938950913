
import React, { useEffect, useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import '../Pages/Scrolltop.css'

const ScrollProgress = () => {
    const [scrollValue, setScrollValue] = useState(0);
    const [visible, setVisible] = useState(false);

    const calcScrollValue = () => {
        const pos = document.documentElement.scrollTop;
        const calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollValue = Math.round((pos * 100) / calcHeight);
        setScrollValue(scrollValue);

        if (pos > 100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.onscroll = calcScrollValue;
        window.onload = calcScrollValue;
        return () => {
            window.onscroll = null;
            window.onload = null;
        };
    }, []);

    const scrollToTop = () => {
        document.documentElement.scrollTop = 0;
    };

    return (
        <div 
            id="progress" 
            style={{ 
                display: visible ? 'grid' : 'none', 
                background: `conic-gradient(#03cc65 ${scrollValue}%, #fff ${scrollValue}%)`
            }}
            onClick={scrollToTop}
        >
            <span id="progress-value"> <FaArrowUp /></span>
        </div>
    );
};

export default ScrollProgress;
