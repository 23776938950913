import React, { useState, useEffect } from "react";

import "../assets/css/index.css";
import Meta from "./Meta";
import HeroSlider from "../components/include/HeroSlider";
import Card from "../components/include/Card";
import Technology from "../components/include/Technology";
import LetsWork from "../components/include/LetsWork";
import BalanceTime from "../components/include/BalanceTime";
import Chooseus from "../components/include/Chooseus";
import CountSection from "../components/include/CountSection";
import Portfolio from "../components/include/Portfolio";

const Home = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/page/2097`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []); 
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <HeroSlider />
      <Card />
      <Technology />
      <LetsWork />
      <BalanceTime></BalanceTime>
      <Portfolio></Portfolio>
      <Chooseus></Chooseus>
      <CountSection></CountSection>

    </div>
  );
};

export default Home;
