import React from "react";
import Template from "../image/template.webp"
import feature from "../image/feature3.webp";
import dealopmemt from "../image/app-development.webp";
// import "../../Pages/Card.css";

const Card = () => {
  let data = [
    {
      imgSrc: Template,
      title: "Web Designing",
      description:
        "Most of us think that Web Design is a normal Designing process but it's a different concept, it involves the integration of image, banner",
    },
    {
      imgSrc: feature,
      title: "Web Development",
      description:
        "With rapid development in Website designing and development techniques and technologies, website development becomes easier",
    },
    {
      imgSrc: dealopmemt,
      title: "Search engine optimization",
      description:
        "SEO, is one of those things that those of us in the web design world kind of take for granted, but if you are new to the game we can explain",
    },
  ];
  return (
    <div className="container" id="thumbnail-style">
      {data.map((value, index) => (
        <div className="col-md-4" key={index}>
          <div className="thumbnail">
            <img
              src={value.imgSrc}
              alt={value.title}
              loading="lazy"
              width="75px"
              height="100%"
            />
            <div className="caption">
              <h1>{value.title}</h1>
              <p>{value.description}</p>
              <hr />
              <a href="services.php" className="btn btn-primary btn-sm">
                Read More
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Card;
