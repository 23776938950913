import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const YoutubeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reviews = [
    { url: "https://www.youtube.com/embed/W3Nt3lnJLUM" },
    { url: "https://www.youtube.com/embed/xhg6bCboLD0" },
    { url: "https://www.youtube.com/embed/JOns6D8fZpA" },
    { url: "https://www.youtube.com/embed/1w7ujjVdLmM" },
  ];

  return (
    <div className="video-container">
      <div className="container p-0">
        {isDesktop ? (
          <div className="video-grid">
            {reviews.map((item, i) => (
              <div className="video-item" key={i}>
                <LazyLoad height={300} offset={100} once>
                  <iframe src={item.url} frameBorder="0" allowFullScreen></iframe>
                </LazyLoad>
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings}>
            {reviews.map((item, i) => (
              <div className="video-item" key={i}>
                <LazyLoad height={300} offset={100} once>
                  <iframe src={item.url} frameBorder="0" allowFullScreen></iframe>
                </LazyLoad>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default YoutubeSlider;
