import React from "react";
import Slider from "react-slick"; 
import { FaStar,FaGoogle } from "react-icons/fa";
import screenshot from "../image/mm.png";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const TestimonialCarousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let reviews = [
    {
      'head': 'H',
      'name': 'Harish kumar',
      'year': 'a months ago',
      'des': "Fahim worked on multiple projects with us and he is very experienced regarding Website development. One of the best Magento developer i know.",
    },
    {
      'head': 'G',
      'name': 'Gurjas Bedi',
      'year': '5 years ago',
      'des': " After number of cheaters and fake developers, I finally found Fahim who helped me in developing my website just like I wanted. After completion of website services provided are true and reasonable . Would recommend everyone to get their work done from him.",
    },
    {
      'head': 'G',
      'name': 'Gaurav Arora',
      'year': '5 years ago',
      'des': "Fahim has good conceptional knowledge regarding his developing domain. He has good command on different coding languages. He is hardworking and always reachable to get help.",
    },
    {
      'head': 'D',
      'name': 'Diwakar kumar',
      'year': '3 years ago',
      'des': "One of the best Magento developer I have met. He help me out from several typical problems. We have also worked together for several projects.",
    },
    {
      'head': 'J',
      'name': 'Jatin nanda',
      'year': '3 years ago',
      'des': "Fahim is a Hard Working and Professional in his work. A Full-Stack  Developer with great knowledge. Will Surely Recommend his work.",
    },
    {
      'head': 'G',
      'name': 'Gamer Buddy',
      'year': '6 years ago',
      'des': "Fahim Shakir is the best website developer. They can working PHP, Wordpress, Megento, opencart, Durpal and etc. They provides impressive services to there clients.",
    },
    {
      'head': 'S',
      'name': 'Shiraj Alam',
      'year': '5 years ago',
      'des': "Fahim is very good developer he delivered some very critical projects for CTS. Really appreciate for his work .Keep it up.",
    },
    {
      'head': 'V',
      'name': 'Vishu Agarwal',
      'year': 'a month ago',
      'des': "Fahim Website Developer is outstanding. Excellent job, wonderful, and the Very punctual with almost all the requirements. Highly recommended.",
    },
    {
      'head': 'F',
      'name': 'Foysal Afridi',
      'year': 'a month ago',
      'des': "Great experience working on my personal website development project with Fahim Shakir Web Developer. He was professional and creative. Their dedicated approach to the work is commendable. I got user-friendly and incredible looking website within the stipulated time outstanding Ecommerce Website Designing and development. Thanks for giving my brand and my business great web presence and an added online edge.",
    },
  ]

  return (
    <section className="section-reviews">
      <div>
        <h4 className="text-head">
          5.0 <FaStar className="orang-color" />{" "}
          <FaStar className="orang-color" /> <FaStar className="orang-color" />{" "}
          <FaStar className="orang-color" /> <FaStar className="orang-color" />{" "}
          Google Reviews
        </h4>
      </div>

      <div className="container f-margin contan">
        <Slider {...settings}>
          
          {reviews.map((item,i) => (
              <div className="g1" key={i}>
                <div className="h-flex">
                  <div className="h-flex">
                    <div className="h">{item.head}</div>
                    <div>
                      <h3 className="clf">{item.name}</h3>
                      <p className="year">{item.year}</p>
                    </div>
                  </div>
                  <span>
                    <img src={screenshot} alt="google" loading="lazy" width="23px" height="20px" />
                  </span>
                </div>
                <div className="st5">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <p className="typ">
                {item.des}
                </p>
              </div>
            ))}
          
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
