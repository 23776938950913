import React, { useState, useEffect } from "react";
import OurPortfolio from "./OurPortfolio";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./Port.css";
import Allmain from "./Allmain";
import Slider from "react-slick";

import gradeninja from "../../components/image/portfolio/gradeninja.png";
import wellness from "../../components/image/portfolio/wellness.png";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const cardsData = [
  {
    imageSrc: 'https://i.ibb.co/NpDRcdr/ytviews.jpg',
    paragraphText: '3rd party APIs based',
    Moreinfo: "https://www.ytviews.in/",
  },
  {
    imageSrc: 'https://www.fahimshakir.com/blog/wp-content/uploads/2024/09/cuber.jpg ',
    paragraphText: 'cybersectrainings',
    Moreinfo: "https://cybersectrainings.com/",
  },
  {
    imageSrc: gradeninja,
    paragraphText: 'AI based',
    Moreinfo: "https://maths.gradeninja.ai/effortlessly/",
  },
  {
    imageSrc:  'https://www.fahimshakir.com/blog/wp-content/uploads/2024/11/Screenshot-from-2024-11-14-17-19-15.png',
    paragraphText: 'glazedbyc',
    Moreinfo: "https://glazedbyc.com/",
  }

];



const App = () => {

  const respos = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
    ],
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // const images = cardsData.map(card => card.imageSrc);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="prot-items">
    {isDesktop ? (
    <div className="haxx">
      {cardsData.map((card, index) => (
         <Allmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          link={card.Moreinfo}
          images={card.imageSrc}
        />
      ))}
      
     
    </div>
    ) : (
      <Slider {...respos}>
        {cardsData.map((card, index) => (
         <Allmain
          key={index}
          imageSrc={card.imageSrc}
          paragraphText={card.paragraphText}
          Moreinfo={card.Moreinfo}
          images={card.imageSrc}
        />
      ))}
      
      </Slider>
    )}
    </div>
  );
};

export default App;
