import React from "react";

const mydata = [
  {
    id: 1,
    icon: "fa fa-tasks",
    dis: "Easy to use Control Panel",
  },
  {
    id: 2,
    icon: "fa-brands fa-aws",
    dis: "Cloud AWS, Azure hosting",
  },
  {
    id: 3,
    icon: "fa-regular fa-clock",
    dis: "24/7 Hours Support",
  },
  {
    id: 4,
    icon: "fa-sharp fa-light fa-desktop",
    dis: "Website optimization solution",
  },
  {
    id: 5,
    icon: "fa fa-shield",
    dis: "DDOS Protection",
  },
  {
    id: 6,
    icon: "fa fa-usd",
    dis: "Money back guarantee",
  },
  {
    id: 7,
    icon: "fa-thin fa-backpack",
    dis: "Free nightly backups",
  },
  {
    id: 8,
    icon: "fa-regular fa-thumbs-up",
    dis: "Outstanding reliability",
  },
  {
    id: 9,
    icon: "fa fa-random",
    dis: "Powerful, fast fervers",
  },
  {
    id: 10,
    icon: "fa fa-random",
    dis: "Free website transfer",
  },
  {
    id: 11,
    icon: "fa fa-tachometer",
    dis: "Superior technology",
  },
  {
    id: 12,
    icon: "fa fa-random",
    dis: "Secured website",
  },
  {
    id: 13,
    icon: "fa-regular fa-lightbulb",
    dis: "No overloaded servers",
  },
  {
    id: 14,
    icon: "fa fa-random",
    dis: "Email configuration",
  },
];

const Chooseus = () => {
  
  return (
    <>
      <section className="choose-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center sec-review-ti">Why Choose <span>Us</span></h3>
              <p className="des-choose">Expertise and innovation fuel our development, ensuring tailored solutions that exceed expectations</p>
            </div>
            <div className="col-sm-12 p-0	">
                <div className="choose-us-section">
                  {mydata.map((item, i) => {
                    return (
                      <div className="card" key={i}>
                      <div className="icon">
                        <i className={item.icon}></i>
                      </div>
                      <div className="title">{item.dis}</div>
                    </div>
                    );
                  })}
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chooseus;
