import React, { useEffect, useState } from 'react';
import '../Ourportfolio/Main.css'
import MainPortfolio from './MainPortfolio'
import Meta from '../Meta';

const Portfolio = () => {

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/page/2091`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []); 
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
    <div className='main-port'>
        <h1 className='head-port'>OUR PORTFOLIO</h1>
        <h2 className='head2port'>I'm a kibitzer with a broad portfolio. </h2>
        <h3 className='inno-head2'>- Our Innovative Creations - </h3>
        <p className='discipline'>My name is Fahim Shakir Sidiqui. In the discipline of Web Development and Design I am prominent as PHP Programmer / Web Developer / Web Freelancer. I am reputated by my outstanding work, excellent programming skills and versatile nature of program coding with Software and Website Development.
           Read More Sign up for special offers</p>  
    </div>
    <MainPortfolio/>
    </div>
  )
}

export default Portfolio