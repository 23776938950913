import { useEffect } from "react";

const Meta = ({ title, description, keywords, canonical, head_tag }) => {
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Helper function to set meta tags
    const setMetaTag = (name, content) => {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (element) {
        element.setAttribute("content", content);
      } else {
        element = document.createElement("meta");
        element.name = name;
        element.content = content;
        document.head.appendChild(element);
      }
    };

    // Set the canonical link
    const setCanonicalLink = (href) => {
      let link = document.querySelector('link[rel="canonical"]');
      if (link) {
        link.setAttribute("href", href);
      } else {
        link = document.createElement("link");
        link.rel = "canonical";
        link.href = href;
        document.head.appendChild(link);
      }
    };

    // Set custom head tag
    const setCustomHeadTag = (tagContent) => {
      if (tagContent) {
        const headTagElement = document.createElement("div");
        headTagElement.innerHTML = tagContent; // Insert custom HTML
        document.head.appendChild(headTagElement);
      }
    };

    setMetaTag("description", description);
    setMetaTag("keywords", keywords);
    setCanonicalLink(canonical || window.location.href);
    setCustomHeadTag(head_tag); // Add custom head tag

    // Cleanup: Remove custom head tag on unmount if needed
    return () => {
      const headTagElement = document.head.querySelector("div");
      if (headTagElement) {
        document.head.removeChild(headTagElement);
      }
    };
  }, [title, description, keywords, canonical, head_tag]);

  return null;
};

export default Meta;
