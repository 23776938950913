import React, { useState } from "react";
import { GoMail } from "react-icons/go";
import { FaSkype } from "react-icons/fa";
import '../Contact/Contact.css'
import ContactFormHtml from "../../components/include/ContactFormHtml"

const ContactForm = () => {
   
  return (
    <>
      <div className="contact-bg">
        <div className="cards-container">
          <div className="d-flax">
              <div className="left-side">
                <h2>Looking for more information?</h2>
                <div className="section-title-bottom-line"></div>
                <ContactFormHtml />
              </div>
            <div className="right-side">
              <h2>Our Address</h2>
              <div className="section-title-bottom-line"></div>
              <div className="panel1">
                <ul className="no-bullet">
                  <li>
                    <strong>Fahim Shakir Sidiqui</strong>
                  </li>
                  <li>Block C-11B, Top Floor, New</li>
                  <li>Ashok Nagar New Delhi 110096, INDIA.</li>
                </ul>
                <hr />
                <ul className="no-bullet">
                  <li>
                    <strong>Follow</strong>
                  </li>
                </ul>
                <ul className="small-block-grid-1 chooseus">
                  <li>
                    <GoMail />
                    <a href="mailto:info@fahimshakir.com">info@fahimshakir.com</a>
                  </li>
                  <li>
                    <GoMail />
                    <a href="mailto:fahimshakir786@gmail.com">fahimshakir786@gmail.com</a>
                  </li>
                  <li>
                    <FaSkype />
                    <a href="skype:fahim.shakir40">fahim.shakir40</a>
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>
                    <a href="tel:09718925775">09718925775</a>
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>
                    <a href="tel:09268751849">09268751849</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

