import React, { useEffect, useState } from 'react';
import AboutHero from "./AboutHero";
import Meta from '../Meta';

const About = () => {

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/page/2087`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Service not found');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.title) {
          setPageData(data);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching service:', error);

      });
  }, []);
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords={pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <AboutHero />
    </div>
  );
};

export default About;
