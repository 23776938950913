import React from 'react'
import Meta from './Meta'

const Blog = () => {
  return (
  <div>
    <Meta
    title="Blog - Website Development and Maintenance | Part Time and Full Time Developer/ Freelancer in India, Delhi NCR"
    />
  </div>
  )
}

export default Blog