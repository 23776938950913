import React from "react";

const CountSection = () =>{
    return (
        <div className="count_section_header">
            <div className="container">
                <div className="count_section_items">
                    <div className="count_section_item">
                        <div className="content">
                            <strong>1000+</strong>
                            <h3>Completed Projects</h3>
                        </div>
                    </div>
                    <div className="count_section_item">
                        <div className="content">
                            <strong>Best Offer</strong>
                            <h3>Delivered On Time</h3>
                        </div>
                    </div>
                    <div className="count_section_item">
                        <div className="content">
                            <strong>Best Web Maker</strong>
                            <h3>100% Qc faster production</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CountSection;