import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Check if the script is already present
    if (!document.getElementById('tawkToScript')) {
      var $_Tawk_API = {}, $_Tawk_LoadStart = new Date();

      (function() {
        var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/553e6490101e2d35114c9790/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s1.id = 'tawkToScript';
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, []);

  return (
    <div>
      {/* The chat will be automatically embedded in your site */}
    </div>
  );
};

export default TawkToChat;
