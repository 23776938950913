import React, { useEffect, useState } from "react";
import ResumeHero from "./ResumeHero";
import ResumeCard from "./ResumeCard";
import ResumeSection from "./ResumeSection";
import ResumeMain from "./ResumeMain";
import Meta from "../Meta";

const Resume = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/page/2095`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []); 

    console.log(pageData);
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <ResumeHero />
      <ResumeCard />
      <ResumeSection />
      <ResumeMain />
    </div>
  );
};

export default Resume;
