import React from "react";
import '../Resume/Resume.css'

const Remain = ({ paragraphText }) => {
  return (
    <>
        <li>{paragraphText}</li>
    </>
  );
};

export default Remain;
