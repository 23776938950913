import React, { useState } from "react";
import "../Pages/FormOpen.css";
import tanwork from "../components/image/10-work@2x.png";
import ContactFormHtml from "../components/include/ContactFormHtml"

const OpenForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
 

  return (
    <div>
      <button className="openPopup1" onClick={togglePopup}>
        Get In Touch
      </button>

      {isOpen && (
        <>
          <div className="overlay" onClick={togglePopup}></div>
          <div className={`popup-content1 dis-flex`}>
            <span className="close-btn1" onClick={togglePopup}>
              &times;
            </span>

            <div className="left-background">
              <div>
                <h3>Get A Free Quote Now</h3>
                <p>
                  {" "}
                  Please complete this form to be connected by one of our
                  experts.{" "}
                </p>
              </div>
              <div className="tanwork">
                <img src={tanwork} alt="tanwork" />
              </div>
            </div>
            <div className="right-background">
             <ContactFormHtml />
            </div>
          </div>
        </>
      )
      }
    </div >
  );
};

export default OpenForm;
