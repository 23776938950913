import React from "react";
import "../Pages/Whatsapp.css";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppPopup = () => {
  return (
    <>
      <div className="whatfixed">
        <a
          href="https://api.whatsapp.com/send/?phone=919718925775&text=Hi, I am full stack PHP, Wordpress, Woocommerce, Shopify, Magento, PHP Laravel, HTML, CSS, Bootstrap, jQuery, JavaScript, Flights, Hotel, Bus, Bank EMI,APIs integration, Website Developer and Digital Marketing experience (SEO,SMO,GTM,GA4,GMC etc) Project manage from cloud server AWS, Jira, Git, with over 11.7 years experience.&type=phone_number&app_absent=0"
          target="_blank"
          className="whatsapp-btn"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="icon-what" />
        </a>
      </div>
    </>
  );
};

export default WhatsAppPopup;
