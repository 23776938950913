import React from "react";
import screan1 from "../image/Screen.svg";
import screan2 from "../image/Screen2.svg";
import screan3 from "../image/Screen3.svg";

const LetsWork = ()=>{
    let work = [
        {
            'imgSrc': screan1,
            'title': 'WE DISCUSS',
            'des': 'We’re a full-service Web Development agency that hustles hard to makes users happy while infusing everything we do with the heart.',
        },
        {
            'imgSrc': screan2,
            'title': 'WE DEVELOP',
            'des': 'We see beyond the insights and take you to the eye stand.',
        },
        {
            'imgSrc': screan3,
            'title': 'WE DELIVER',
            'des': 'As a multifaceted Web Development agency, we craft beautiful solutions that outdo business goals and garner recognition.',
        },
    ]
    return (
        <>
            <div className="lets_work_header">
                <div className="container">
                    <div className="lets_work_title">
                        <h2>Let's Work Together</h2>
                    </div>
                    <div className="lets_work_lead">
                        <p>We’re passionate about brilliant ideas and the execution that brings it all together in one beautiful experience. If you are too, call or send us an email to get started.</p>
                    </div>
                    <div className="lets_work_items">
                        {work.map((value, index)=> (
                            <div className="lets_work_item" key={index}>
                                <div className="item_cloums">
                                    <div className="svg_loaded">
                                        <img
                                            src={value.imgSrc}
                                            alt={value.title}
                                            loading="lazy" width='50px' height='50px'
                                        />
                                    </div>
                                </div>
                                <div className="content">
                                    <h2>{value.title}</h2>
                                    <p>{value.des}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LetsWork;