import React, { useState, useEffect } from "react";
import Slider from "react-slick";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const Technology = () => {
    const respo = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      };
    
      const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

      
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


    const ourtecnology = [
        {
          id: 1,
          title: "PHP Development",
          imgSrc: "https://www.fahimshakir.com/img/php.png",
        },
        {
          id: 2,
          title: "Laravel Development",
          imgSrc: "https://www.fahimshakir.com/img/laravel.webp",
        },
        {
          id: 3,
          title: "Codeigniter Development",
          imgSrc: "https://www.fahimshakir.com/img/codignitor.png",
        },
        {
          id: 4,
          title: "Wordpress Development",
          imgSrc: "https://www.fahimshakir.com/img/wordpress.png",
        },
    
        {
          id: 5,
          title: "Woocommerce Development",
          imgSrc: "https://www.fahimshakir.com/img/woocommerce.webp",
        },
        {
          id: 6,
          title: "Shopify Development",
          imgSrc: "https://www.fahimshakir.com/img/shopify.png",
        },
        {
          id: 7,
          title: "Magento Development",
          imgSrc: "https://www.fahimshakir.com/img/magento.png",
        },
        {
          id: 8,
          title: "Opencart Development",
          imgSrc: "https://www.fahimshakir.com/img/opencart.png",
        },
        {
          id: 9,
          title: "Prestashop Development",
          imgSrc: "https://www.fahimshakir.com/img/presta.png",
        },
        {
          id: 10,
          title: "Joomla Development",
          imgSrc: "https://www.fahimshakir.com/img/joomla.png",
        },
        {
          id: 11,
          title: "Drupal Development",
          imgSrc: "https://www.fahimshakir.com/img/drupal.png",
        },
        {
          id: 12,
          title: "Moodle Development",
          imgSrc: "https://www.fahimshakir.com/img/moodle.webp",
        },
        {
          id: 13,
          title: "Mobile Application Development",
          imgSrc: "https://www.fahimshakir.com/img/android-studio.webp",
        },
        {
          id: 14,
          title: "Payment Gateway Integration",
          imgSrc: "https://www.fahimshakir.com/img/gateway.webp",
        },
        {
          id: 15,
          title: "3rd Party APIs Integration",
          imgSrc: "https://www.fahimshakir.com/img/gateway.webp",
        },
        {
          id: 16,
          title: "HTML5",
          imgSrc: "https://www.fahimshakir.com/img/HTML5.webp",
        },
        {
          id: 17,
          title: "React Js",
          imgSrc: "https://www.fahimshakir.com/img/react.svg",
        },
        {
          id: 18,
          title: "CSS",
          imgSrc: "https://www.fahimshakir.com/img/CSS.webp",
        },
        {
          id: 19,
          title: "Bootstrap",
          imgSrc: "https://www.fahimshakir.com/img/bootstrap.svg",
        },
        {
          id: 20,
          title: "Vue Js",
          imgSrc: "https://www.fahimshakir.com/img/Vue.webp",
        },
    ];
  return (
    <section className="use-technology">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="text-center sec-review-ti">Use Technology</h2>
                    <p className="des-choose">Crafting innovative web experiences with cutting-edge technology to elevate digital presence and user engagement.</p>
                </div>
                <div className="col-sm-12 p-0	">
                    <div className="use-technology-section">
                        <div className="row">
                            <div className="col-md-12">
                                
                                {isDesktop ? (
                                    <ul className="desktop-list">
                                        {ourtecnology.map((item,i) => (
                                             <li key={i}>
                                                <img
                                                    src={item.imgSrc}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    width="30px"
                                                    height="35px"
                                                    />
                                                
                                                {item.title}
                                            </li>
                                        ))}
                                        </ul>
                                    ) : (
                                    <Slider {...respo}>
                                        {ourtecnology.map((item,i) => (
                                        <li key={i}>
                                            <img
                                              src={item.imgSrc}
                                              alt={item.title}
                                              loading="lazy"
                                              width="30px"
                                              height="35px"
                                            />
                                            {item.title}
                                        </li>
                                        ))}
                                    </Slider>
                                    )}
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </section>
  );
};
export default Technology;
