import React, { useEffect, useState } from 'react';
import  hrline from '../../components/image/hr-line.png'
import '../Contact/Contact.css'
import ContactCard from './ContactCard';
import ContactForm from './ContactForm';
import Meta from '../Meta';

const Contact = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/page/2089`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data && data.title) {
            setPageData(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
          
      });
  }, []);
  return (
    <div>
      <Meta
      title={pageData?.seo_title || ''}
      description={pageData?.seo_description || ''}
      keywords= {pageData?.seo_keywords || ''}
      head_tag={pageData?.head_tag || ''}
      />
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7006.316500194394!2d77.30685900000002!3d28.595029!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce48c3fffffff%3A0x6f083fc47a4f5e6b!2sFahim%20Website%20Developer.!5e0!3m2!1sen!2sus!4v1719895979490!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
        <div className='tell'>
          <h1 className='contact'>Contact us and tell us what you think</h1>
          <img src={hrline} alt="hrline" loading="lazy"/>
        </div>

        <ContactCard/>
        <ContactForm/>
    </div>
  );
};

export default Contact;
