// SkeletonLoader.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const SkeletonDiv = styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(90deg, #f6f7f8 25%, #edeef1 50%, #f6f7f8 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite;
  border-radius: 4px;
`;

const SkeletonLoader = ({ width, height }) => {
  return <SkeletonDiv style={{ width, height }} />;
};

export default SkeletonLoader;
