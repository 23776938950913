import React from "react";
import SerCard from "./SerCard";
import "../OurService/service.css";
import Payment from "../../components/image/Payment-Gateway.webp";
import third from "../../components/image/third-party.webp";
import aws from "../../components/image/aws.png";

const ServiceCard = () => {
  const cardsData = [
    {
      imageSrc: "https://www.fahimshakir.com/img/designing.jpg",
      HedingText: "Website Design",
      paragraphText:
        "Most of us think that Web Design is a normal Designing process but its a different concept, it involves the integration of image, banner, header, footer, content, logos and convert",
    },
    {
      imageSrc: "https://www.fahimshakir.com/img/development.jpg",
      HedingText: "Web Development",
      paragraphText:
        "With rapid development in Website designing and development techniques and technologies, website development becomes easier now days. But quality website",
    },
    {
      imageSrc: "https://www.fahimshakir.com/img/seo.jpg",
      HedingText: "Search Engine Optimization",
      paragraphText:
        "Search engine optimization, or SEO, is one of those things that those of us in the web design world kind of take for granted, but if you are new to the game we can explain it",
    },
    {
      imageSrc: Payment,
      HedingText: "Payment Gateway Integration",
      paragraphText:
        "A merchant should consider selecting a payment gateway that allows for international payments and accepts different currencies. A presence on the Internet opens up the whole world as a market to you.",
    },
    {
      imageSrc: third,
      HedingText: "3rd Party APIs Integration",
      paragraphText:
        "APIs are an essential part of technology today and 3rd party API integration plays a pivotal role in Flights, Hotel, Bus, Bank, Shaping the APIs we use and the experiences we have.",
    },
    {
      imageSrc: aws,
      HedingText: "Cloud AWS, Azure hosting ",
      paragraphText:
        "All data scientists are assumed to be familiar with Amazon Web Services (AWS).Microsoft Azure is a cloud computing service that allows users to create, modify, test, deploy, and maintain applications.",
    },
  ];

  return (
    <>
      <div className="container4512">
        {cardsData.map((card, index) => (
          <SerCard
            key={index}
            imageSrc={card.imageSrc}
            HedingText={card.HedingText}
            paragraphText={card.paragraphText}
          />
        ))}
      </div>
    </>
  );
};

export default ServiceCard;
